<template>

  <v-container id="orderCreatePage" class="h-100">

    <div class="py-3 mb-5">
      <div class="top-text my-3">
        <h1 class="title is-1 thin black--text">Куда</h1>
        <h1 class="title is-1 black--text">везём?</h1>
      </div>
    </div>

    <div class="remaining-space">

        <b-field label="Откуда">
            <b-autocomplete
              v-model="address_from"
              :data="suggestions_from"
              clearable
              size="is-medium"
              @input="getAddresses(true)">
              <template #empty>Адрес не найден</template>
            </b-autocomplete>
        </b-field>

        <b-field label="Куда">
          <b-autocomplete
            v-model="address_to"
            :data="suggestions_to"
            clearable
            size="is-medium"
            @input="getAddresses(false)">
            <template #empty>Адрес не найден</template>
          </b-autocomplete>
        </b-field>

    </div>

    <div class="pa-3 pb-0" style="text-align: center;">
        <p class="buttons mb-0" style="display: flex; justify-content: space-between;">
            <button class="button is-light is-medium" @click="$router.push('/main')">
                <span>Назад</span>
            </button>

            <button class="button is-light is-medium" @click="goNext">
                <span>Далее</span>
                <span class="icon is-medium">
                    <i class="fas fa-chevron-right"></i>
                </span>
            </button>
        </p>
    </div>

  </v-container>

</template>

<script>
export default {
  name: 'OrderCreating',
  data() {
    return {
    };
  },
  created() {
    /*console.log('Тип заказа: ');
    console.log(this.$store.getters.BLOCK_TYPE);*/
    if (this.$store.getters.BLOCK_TYPE == null) {
      this.$router.push('/main');
    }
  },
  computed: {
    address_from: {
      get() {
        return this.$store.getters.ADDRESS_FROM;
      },
      set(value) {
        this.$store.commit("SET_ADDRESS_FROM", value);
      },
    },
    address_to: {
      get() {
        return this.$store.getters.ADDRESS_TO;
      },
      set(value) {
        this.$store.commit("SET_ADDRESS_TO", value);
      }
    },
    suggestions_from: {
      get() {
        return this.$store.getters.SUGGESTIONS_FROM;
      },
      set(value) {
        this.$store.commit("SET_ADDRESSES_FROM", value);
      },
    },

    suggestions_to: {
      get() {
        return this.$store.getters.SUGGESTIONS_TO;
      },
      set(value) {
        this.$store.commit("SET_ADDRESSES_TO", value);
      },
    },

  },
  methods: {
    goNext() {
      if ((this.address_from != null && this.address_from !== "") || (this.address_to != null && this.address_to !== "")) {
        this.$router.replace('/order/create/2');
      } else {
        this.$buefy.toast.open({
          message: 'Заполните обязательные поля',
          type: 'is-warning'
        });
      }
    },
    getAddresses(type) {
      if(type) {
        if (this.address_from != null && this.address_from !== "") {
          this.$store.dispatch("GET_ADDRESSES_STRING", { text: this.address_from, type: true });
        }
      } else {
        if (this.address_to != null && this.address_to !== "") {
          this.$store.dispatch("GET_ADDRESSES_STRING", { text: this.address_to, type: false });
        }
      }
    }
  }
};

</script>

<style scope>
#orderCreatePage .block-content {
  text-align: center;
}

#orderCreatePage .block-content img {
  max-width: 100%;
  height: auto;
}

#orderCreatePage .grid-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-gap: 10px;

  width: 100%;
  height: 100%;
}

#orderCreatePage .grid-item {
  padding: 20px;
  font-size: 30px;
  text-align: center;

  border-radius: 20px;
  cursor: pointer;
}

#orderCreatePage .title.is-1 {
  font-size: 4.5rem !important;
}

#orderCreatePage .title.is-1.thin {
  font-weight: 300;
}

#orderCreatePage.container {
  display: flex;
  flex-direction: column;
}

.remaining-space {
  flex-grow: 1;
}

#orderCreatePage .autocomplete.control .dropdown-item span {
  white-space: normal !important;
}
</style>
